import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="bg-white pt-32 md:pt-64 pb-24 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64">
      <div className="max-w-lg body-text text-navy">
        <p ref={ref} className={`animated ${inView ? 'fadeIn' : 'fadeOut'}`}>
          From the very early days, Afterpay formed a partnership with payments
          firm Touchcorp. Touchcorp provided the back-end payment systems that
          supported the Afterpay product, and would become a key player in
          Afterpay’s future.
        </p>
      </div>
    </div>
  );
};
