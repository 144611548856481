import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';
import ScrollTracker from '../scroll-tracker';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile
          ? 0
          : top < height && top > -height
          ? top
          : 0;

        let rawProgress,
          progress = 1;

        if (offsetY !== 0) {
          rawProgress = (height - top) / height;
          progress = rawProgress >= 1 ? 1 + (rawProgress % 1) / 2 : rawProgress;
        }

        return (
          <div className="hero">
            <div className="bg">
              <div className="bg-content">
                <div
                  className="grid"
                  style={{
                    transform: `translateZ(0) translateX(-50%) rotateX(${progress *
                      80}deg)`,
                  }}
                >
                  <div className="rows" />
                  <div className="cols" />
                </div>
              </div>
            </div>
            <div className="hero-text-container">
              <HeroText date="Februrary 2015" lineA="The Early" lineB="Days" />
              <HighlightTextHeading>
                A key early partnership
              </HighlightTextHeading>
            </div>
            {!mobile && (
              <div
                className="absolute z-40"
                style={{
                  width: '25vw',
                  top: '15vh',
                  right: '26vw',
                }}
              >
                <svg
                  width="100%"
                  height="423"
                  viewBox="0 0 367 423"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M178.289 97.3352L126.437 82.0641L165.588 44.795L178.289 97.3352Z"
                    fill="#FF5A7D"
                    transform={`translate(0 ${offsetY / 12})`}
                  />
                  <path
                    d="M150.014 348.359L134.486 246.976L230.05 284.22L150.014 348.359Z"
                    stroke="#143673"
                    strokeWidth="2"
                    transform={`translate(0 ${offsetY / 6})`}
                  />
                  <path
                    d="M230.527 26.0098L236.62 34.7707L225.987 35.6672L230.527 26.0098Z"
                    stroke="#B1F8FC"
                    strokeWidth="2"
                    transform={`translate(0 ${offsetY / 16})`}
                  />
                  <path
                    d="M331.557 333.597L312.966 302.412L349.269 301.904L331.557 333.597Z"
                    fill="#143673"
                    transform={`translate(0 ${offsetY / 10})`}
                  />
                  <path
                    d="M269.402 147.005L262.439 154.437L259.485 144.691L269.402 147.005Z"
                    fill="#B1F8FC"
                    transform={`translate(0 ${offsetY / 20})`}
                  />
                </svg>
              </div>
            )}
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
