import React from 'react';

export default class StickyScroller extends React.Component {
  ref = React.createRef();
  observer = null;

  constructor(props) {
    super(props);

    this.state = {
      rect: {},
      innerRect: {},
    };
  }

  componentDidMount() {
    const options = {
      rootMargin: '25% 0px 25% 0px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        window.addEventListener('scroll', this.handleScroll);
      } else {
        window.removeEventListener('scroll', this.handleScroll);
      }
    }, options);

    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.observer.unobserve(this.ref.current);
  }

  handleScroll = () => {
    const { current } = this.ref;
    const rect = current.getBoundingClientRect();
    const sticky = current.firstChild;
    const height = sticky.offsetHeight;
    const stickyTop = window.getComputedStyle(sticky).getPropertyValue('top');

    const scrollDiff = sticky.offsetTop - current.offsetTop;
    const heightDiff = current.offsetHeight - sticky.offsetHeight;

    this.setState({
      height,
      rect,
      stickyTop,
      scrollProgress: scrollDiff / heightDiff,
    });
  };

  render() {
    const { top, scrollHeight, contentHeight } = this.props;
    const { rect, height, scrollProgress, stickyTop } = this.state;

    const { innerHeight } = typeof window !== 'undefined' && window;
    const stickyTopOffset = stickyTop ? parseFloat(stickyTop) : 0;

    const topProgress =
      (innerHeight - rect.top) / (innerHeight - stickyTopOffset);
    const bottomProgress = rect.bottom / innerHeight;

    return (
      <div ref={this.ref} style={{ height: scrollHeight || '200vh' }}>
        <div
          className="sticky"
          style={{
            top: top || 0,
            height: contentHeight || '100vh',
          }}
        >
          {this.props.children({
            inViewRatio: (innerHeight - rect.top) / (innerHeight + rect.height),
            topProgress: topProgress ? topProgress : 0,
            bottomProgress,
            scrollProgress,
            rect,
            height,
          })}
        </div>
      </div>
    );
  }
}
