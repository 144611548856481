import React from 'react';

import Hero from '../../components/02-technology/hero';
import Intro from '../../components/02-technology/intro';
import Pacman from '../../components/02-technology/pacman';
import PacmanGrid from '../../components/02-technology/pacman-grid';
import PolyCluster from '../../images/02-technology/intro-polycluster.inline.svg';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>02. The Early Days</div>
      </div>
      <Hero />
      <Intro />
      <div className="relative">
        <div className="w-3/4 lg:w-2/3">
          <PolyCluster className="absolute hidden lg:block z-10 pin-b -mb-8" style={{ left: '55%' }} />
        </div>
      </div>
      <Pacman />
      <PacmanGrid />
    </div>
  );
};
