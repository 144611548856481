import React from 'react';
import { useInView } from 'react-intersection-observer';

export default ({
  children,
  isChapter1 = false,
  bgColor = 'turquoise',
  textColor = 'navy',
  lineColor = 'turquoise',
}) => {
  const [ref, inView] = useInView({
    threshold: 1,
    rootMargin: '0px 0px -12.5% 0px',
    triggerOnce: true,
  });

  return (
    <div
      className={`highlight-text-container  ${isChapter1 ? '' : '-mb-20 md:-mb-48'}`}
    >
      <div
        className={`highlight-text-line-y border-${lineColor} ${
          inView ? 'pin-b' : 'pin-b-100'
        }`}
      />
      <h2 className={`highlight-text absolute pl-4 sm:pl-8 md:pl-16 pin-t-100`}>
        <span
          className={`highlight-text-line-x border-${lineColor} ${
            inView ? 'w-full' : 'w-0'
          }`}
        />
        <span
          ref={ref}
          className={`highlight-text-block ${inView ? 'w-full' : 'w-0'}`}
        >
          <span
            className={`text-${textColor} bg-${bgColor} block leading-normal px-2`}
          >
            {children}
          </span>
        </span>
      </h2>
    </div>
  );
};
