import React from 'react';
import { useInView } from 'react-intersection-observer';

export default (Component, height = '100vh') => {
  return () => {
    const [ref, inView] = useInView({
      threshold: 0,
      rootMargin: '50% 0px 50% 0px',
      triggerOnce: true,
    });

    return <div ref={ref} style={{ height }}>{inView && <Component />}</div>;
  };
};
