import React from 'react';

export default class ScrollTracker extends React.Component {
  ref = React.createRef();
  observer = null;

  constructor(props) {
    super(props);

    this.state = {
      rect: {},
    };
  }

  componentDidMount() {
    const options = {
      rootMargin: '25% 0px 25% 0px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        window.addEventListener('scroll', this.handleScroll);
      } else {
        window.removeEventListener('scroll', this.handleScroll);
      }
    }, options);

    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.observer.unobserve(this.ref.current);
  }

  handleScroll = () => {
    const { current } = this.ref;
    const rect = current.getBoundingClientRect();

    this.setState({
      rect
    });
  };

  render() {
    const { rect } = this.state;

    return (
      <div ref={this.ref}>
        {this.props.children({
          rect
        })}
      </div>
    );
  }
}
