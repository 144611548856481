import React from 'react';

import StickyScroller from '../sticky-scroller';
import Circle from '../../images/circle.inline.svg';
import TriangleArrow from '../../images/02-technology/triangle-arrow.inline.svg';
import Arrow from '../../images/02-technology/arrow.inline.svg';
import TouchCorp from '../../images/02-technology/touch-corp.inline.svg';

import DudeOne from '../../images/02-technology/dude-one.inline.svg';
import DudeTwo from '../../images/02-technology/dude-two.inline.svg';

import ChickOne from '../../images/02-technology/chick-one.inline.svg';
import ChickTwo from '../../images/02-technology/chick-two.inline.svg';
import ChickThree from '../../images/02-technology/chick-three.inline.svg';
import LazyLoadWrap from '../lazy-load-wrap';

const Pacman = () => {
  return (
    <StickyScroller scrollHeight="300vh">
      {({ inViewRatio, scrollProgress, topProgress, bottomProgress }) => {
        let phase = '';

        if (inViewRatio >= 0.2) phase = 'phase-1';
        if (inViewRatio >= 0.4) phase += ' phase-2';
        if (inViewRatio >= 0.6) phase += ' phase-3';
        if (inViewRatio >= 0.8) phase += ' phase-4';

        return (
          <div
            id="table-pacman"
            className={`h-screen overflow-hidden ${phase}`}
          >
            <div className="absolute pin flex overflow-hidden">
              <div className="left-col w-1/4 lg:w-1/3 h-full" />
              <div
                className="relative w-3/4 lg:w-2/3 h-full bg-neon-lavender"
                style={{
                  background: `linear-gradient(${90 +
                    scrollProgress *
                      180}deg, #C99CEF 0%, #143673 100%), #FF5A7D`,
                }}
              >
                <div className="flex absolute pin-x text-right">
                  <div
                    className={`absolute ml-auto max-w-md pin-x mt-20 md:pt-32 px-6 md:pl-12 md:pr-16 xl:pl-4 xl:pr-24 animated ${
                      phase === 'phase-1' ? 'fadeIn' : 'fadeOut'
                    }`}
                  >
                    <p className="highlight-text text-navy lg:ml-12">
                      <span className="bg-turquoise">
                        Touchcorp became not only Afterpay's early technology partner
                        but also one of their largest investors.
                      </span>
                    </p>
                  </div>
                  <div
                    className={`absolute ml-auto max-w-md pin-x mt-20 md:pt-32 px-6 md:pl-12 md:pr-16 xl:pl-4 xl:pr-24 animated ${
                      phase === 'phase-1 phase-2'
                        ? 'fadeIn'
                        : 'fadeOut'
                    }`}
                  >
                    <p className="highlight-text text-white lg:ml-12">
                      In what was a quick role reversal, Afterpay quickly
                      outgrew its early partner and investor. In May 2017,
                      Afterpay announced a merger of the two companies.
                    </p>
                  </div>
                  <div
                    className={`absolute ml-auto max-w-md pin-x mt-20 md:pt-32 px-6 md:pl-12 md:pr-16 xl:pl-4 xl:pr-24 animated ${
                      phase === 'phase-1 phase-2 phase-3'
                        ? 'fadeIn'
                        : 'fadeOut'
                    }`}
                  >
                    <p className="body-text text-white lg:ml-12">
                      With the consumer brand and payments technology under the
                      same umbrella, the stage was now set. At the time, nobody
                      knew, but Afterpay Touch was about to become the fastest
                      growing public company in Australia’s history.
                    </p>
                  </div>
                </div>
                <div
                  id="table-animation-container"
                  className="absolute pin-l pin-b"
                  style={{
                    left:
                      bottomProgress < 0.85
                        ? (0.85 - bottomProgress) * 100 + '%'
                        : 0,
                  }}
                >
                  <div id="table-animation" className="relative">
                    <div
                      id="pacman-container"
                      className="absolute pin-x mx-auto pin-b mb-10"
                      style={{ width: 378, height: 378 }}
                    >
                      <Circle id="table-pacman-circle" fill="#b1f8fc" />
                      <div id="pacman" className="absolute pin-t pin-l" />
                    </div>
                    <TriangleArrow
                      id="table-pacman-triangle-arrow"
                      width="620"
                      className="block mx-auto"
                    />
                    <Arrow
                      id="table-pacman-arrow"
                      className="absolute pin-x mx-auto pin-b"
                    />
                    <div
                      className="absolute pin m-auto mb-10"
                      style={{ width: 378, height: 378 }}
                    >
                      <div id="staff-table" className="relative w-full h-full">
                        <DudeOne
                          id="table-pacman-dude-one"
                          className="absolute pin-t pin-l"
                          style={{ transform: 'translateZ(0) translateY(-39%)' }}
                        />
                        <ChickOne
                          id="table-pacman-chick-one"
                          className="absolute pin-r"
                          style={{ transform: 'translateZ(0) translate(16%, -20%)' }}
                        />
                        <DudeTwo
                          id="table-pacman-dude-two"
                          className="absolute pin-b pin-r"
                          style={{ transform: 'translateZ(0) translate(24%, -28%)' }}
                        />
                        <ChickTwo
                          id="table-pacman-chick-two"
                          className="absolute pin-b pin-l"
                          style={{ transform: 'translateZ(0) translate(33%, 24%)' }}
                        />
                        <ChickThree
                          id="table-pacman-chick-three"
                          className="absolute pin-t pin-l"
                          style={{ transform: 'translateZ(0) translate(-42%, 100%)' }}
                        />
                      </div>
                    </div>
                  </div>
                  <TouchCorp
                    id="table-pacman-touch-corp"
                    className={`absolute pin-x mx-auto pin-b mb-40 ${
                      bottomProgress < 0.85 ? 'hidden' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </StickyScroller>
  );
};

export default LazyLoadWrap(Pacman, '300vh');
