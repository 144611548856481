import React from 'react';

import PolyCluster from '../../images/02-technology/pacman-polycluster.inline.svg';

export default () => {
  return (
    <div className="relative bg-navy" style={{ height: '50vh' }}>
      <div
        className="absolute z-10 w-4/5 h-full"
        style={{
          background: 'linear-gradient(0deg, #C99CEF 0%, #143673 100%)',
        }}
      >
        <div style={{ perspective: '300px' }}>
          <div
            className="absolute grid pin-r"
            style={{
              transform: 'translateZ(0) translateY(-100%) rotateX(52deg)',
            }}
          >
            <div className="rows" />
            <div className="cols" />
          </div>
        </div>
        <PolyCluster className="absolute pin-t pin-r -mt-16 mr-20" />
      </div>
      <div
        className="absolute pin-r w-2/3 h-full"
        style={{
          background:
            'linear-gradient(270deg, #C99CEF 0%, #143673 100%), #FF5A7D',
        }}
      />
    </div>
  );
};
