import React from 'react';
import { useInView } from 'react-intersection-observer';

export default ({ lineA, lineB, date }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className="hero-text">
      <div
        className={`date-text animated delay-250ms ${
          inView ? 'fadeIn' : 'opacity-0'
        }`}
      >
        {date}
      </div>
      <h1
        ref={ref}
        className={`heading-text ${
          inView ? 'in-view' : ''
        }`}
      >
        <span>{lineA}</span>
        <span>{lineB}</span>
      </h1>
    </div>
  );
};
